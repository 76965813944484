import React, {useState, useEffect, useContext} from 'react';
import './WarningPopup.css';
import {AppContext} from "../../context/AppContext";

const WarningPopup = () => {
    const [isPopupVisible, setIsPopupVisible] = useState(false);

    useEffect(() => {
        /*const interval = setInterval(() => {
            const lastShown = localStorage.getItem('lastPopupShown');
            const now = new Date().getTime();
            const threeDaysInMillis = 10 * 1000; // 3 gün = 3 * 24 saat * 60 dakika * 60 saniye * 1000 milisaniye

            // Eğer pop-up daha önce gösterilmemişse veya 10 saniye geçmişse pop-up'ı göster
            if (!lastShown || now - lastShown > threeDaysInMillis) {
                setIsPopupVisible(true);
                localStorage.setItem('lastPopupShown', now); // Şu anki zamanı kaydet
            }
        }, 1000); // Her 1 saniyede bir kontrol et

        return () => clearInterval(interval); // Temizlik işlemi bileşen kaldırıldığında*/

        setIsPopupVisible(true);
        document.body.style.overflow = 'hidden'; // Scroll'u kapat

        return () => {
            // Pop-up kapatıldığında scroll'u geri aç
            document.body.style.overflow = 'auto';
        };
    }, []);

    const closePopup = () => {
        setIsPopupVisible(false);
        document.body.style.overflow = 'auto';
    };

    return (
        <>
            {isPopupVisible && (
                <div className='popup-style'>
                    <div className='popup-content-style'>
                        <h2 className='warning-popup-title'>Uyarı!</h2>
                        <p className='popup-warning-text'>
                            FootyAssist, 2000+ lig için detaylı futbol istatistikleri, canlı skorlar, maç sonuçları, gol / korner / kart tabloları,  fikstürler, iddaa programı ve fazlasını sunan bir analiz platformudur.
                        </p>
                        <p className='popup-warning-text' style={{textDecoration: 'underline'}}>Kesinlikle bahis oynatılmaz!</p>

                        <button className='close-popup' onClick={closePopup}>Tamam</button>
                    </div>
                </div>
            )}
        </>
    );
};

export default WarningPopup;

