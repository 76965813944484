import React, {useContext, useEffect, useState} from 'react';
import './TvGuide.css'
import {fetchTvMatches} from "../../api";
import dayjs from "dayjs";
import noTeamLogo from '../../assets/icons/noTeamLogo.png'
import DocumentMeta from "react-document-meta";
import {tvGuideMeta} from "../../SEO/SeoMetaTags";
import {AppContext} from "../../context/AppContext";

function TvGuide(props) {
    const {lang, timeZone, i18String} = useContext(AppContext);

    const [tvMatchesData, setTvMatchesData] = useState(null);
    const [showMore, setShowMore] = useState(false);
    const [showId, setShowId] = useState(false);
    const [tvCount, setTvCount] = useState(null);

    useEffect(() => {
        fetchTvMatches().then(data => {
            setTvMatchesData(data.data.data);
        })
    }, []);

    const startTime = (value) => {
        return dayjs(value).tz(timeZone).format("HH:mm");
    }

    const showClick = (id) => {
        /*setShowMore(!showMore);*/
        setShowId(id);

    }
    const showLess = (id) => {
        /*setShowMore(!showMore);*/
        if (showId === id) {
            setShowId(undefined);
        }
    }

    const clickStyle = (id) => {
        if (showId === id) {
            return {
                height: '100%'
            }
        } else {
            return {
                overflow: 'hidden'
            }
        }
    }

    const onImageError = (e) => {
        e.target.src = noTeamLogo;
    }

    return (
        <DocumentMeta {...tvGuideMeta[lang]}>
            <div className='tv-guide-general-section'>
                <div style={{display: 'flex', justifyContent: 'flex-start', width: '100%', flexDirection: 'column'}}>
                    <h5 className='tv-screen-title'>The Ultimate Live Football On TV Guide</h5>
                    <p className='tv-provides-explanation'>
                        FootyAssist provides the most up-to-date and accurate listings of Live Football On TV in the World.
                        We have schedules of live football on television from broadcasters including Sky Sports,
                        beIN SPORTS, DAZN, TNT Sports, Premier Sports, BBC, ESPN, ITV and more.
                    </p>
                </div>
                {
                    tvMatchesData &&
                    tvMatchesData.map(data => {
                        return (
                            <div key={data.match.id} className='tv-guide-general-container'>
                                <div className='country-section'>
                                    {/*<img className="tv-country-flag"
                                     src={asd(data)}
                                     alt={''}/>*/}

                                    <span className='tv-area'>{data.match.area} - </span>
                                    <span className='tv-area-and-league'>{data.match.league}</span>

                                    <div>
                                        {
                                            data.tvs.length > 9 &&
                                            <div>
                                                {
                                                    showId === data.match.id ?
                                                        <div>
                                                            <button className='show-less-button'
                                                                    onClick={() => showLess(data.match.id)}>
                                                                {i18String('Show Less')}
                                                            </button>
                                                        </div> :
                                                        <div>
                                                            <button className='show-more-button'
                                                                    onClick={() => showClick(data.match.id)}>
                                                                {i18String('Show More')}
                                                            </button>
                                                        </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div style={clickStyle(data.match.id)} className='teams-and-tv-container'>
                                    {/*TEAMS SECTION*/}
                                    <div className='teams-section'>
                                        <div className='tv-team-container'>
                                            {
                                                !data.match.homeId ?
                                                    <img className="tv-team-logo" src={noTeamLogo} alt=""/>
                                                    :
                                                    <img className="tv-team-logo"
                                                         src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/teams/${data.match.homeId}.png`}
                                                         onError={(e) => onImageError(e)}
                                                         alt={data.match.home}/>
                                            }
                                            <a target={"_blank"} href={`/team-detail/${data.match.homeId}`}
                                               className='tv-team-name'>{data.match.home}</a>
                                        </div>

                                        <div className='versus-and-score-container'>
                                            {data.match.status === 'Fixture' &&
                                                <div className='tv-start-time-container'>
                                                    <span>{startTime(data.match.dateTimeUtc)}</span>
                                                    <span className='tv-versus-text'>V</span>
                                                </div>
                                            }

                                            {data.match.status === 'Played' &&
                                                <span
                                                    className='tv-teams-score'>{data.match.hscore} - {data.match.ascore}</span>}
                                        </div>

                                        <div className='tv-team-container'>

                                            {
                                                !data.match.awayId ?
                                                    <img className="tv-team-logo" src={noTeamLogo} alt=""/>
                                                    :
                                                    <img className="tv-team-logo"
                                                         src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/teams/${data.match.awayId}.png`}
                                                         onError={(e) => onImageError(e)}
                                                         alt={data.match.away}/>
                                            }
                                            <a target={"_blank"} href={`/team-detail/${data.match.awayId}`}
                                               className='tv-team-name'>{data.match.away}</a>
                                        </div>
                                    </div>

                                    {/*TV SECTION*/}
                                    <div className='tv-general-container'>
                                        {
                                            data.tvs.map((tv, i) => {
                                                return (
                                                    <div key={i} className='tv-container'>
                                                        {
                                                            <img className="tv-container-country-flag"
                                                                 src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/countries/${tv.countryId}.png`}
                                                                 alt={`${tv.country} - tv`}/>
                                                        }
                                                        <span className='tv-name'>{tv.tv}</span>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </DocumentMeta>
    );
}

export default TvGuide;
