import React, {useContext, useEffect, useState} from 'react';
import './AndroidApk.css';
import androidMobileViewTR from '../../assets/icons/android-mobile-view-TR.png';
import androidMobileView2TR from '../../assets/icons/android-mobile-view-2-TR.png';
import androidMobileView3TR from '../../assets/icons/android-mobile-view-3-TR.png';
import androidMobileView3EN from '../../assets/icons/android-mobile-view-3-EN.png';
import androidMobileView2EN from '../../assets/icons/android-mobile-view-2-EN.png';
import androidMobileViewEN from '../../assets/icons/android-mobile-view-EN.png';
import {AppContext} from "../../context/AppContext";
import {FormControl, MenuItem, Select} from "@mui/material";
import {languageLists} from "../header/Languages";
import img from "../../assets/icons/instagram.png";
import androidQR from "../../assets/icons/android-app-qr.png";

function AndroidApkView(props) {
    const {lang, setLanguage, i18String} = useContext(AppContext);

    const settingsText = i18String('Go to Settings');
    const securityText = i18String('Find Security');
    const permitText = i18String('Permit Unknown sources');
    const ifUsualWarningText = i18String('If the usual warning about');
    const youWillFindText = i18String('You\'ll find the downloaded app');

    return (
        <div className='androidPlus'>
            <div className='promo'>
                {/*HEADER*/}
                <div className="promoBackground promoBackground--original">
                    <div className="promoWrapper">
                        <div className="promoHeader">
                            <div className="promoLogos">
                                <a href="/">
                                    <div id="promoLogos"
                                         className="logo--mobile promoLogos__logo flashscore-co-uk-white">
                                        <span style={{fontSize: '25px', fontWeight: '600', color: '#fff'}}>FootyAssist</span>
                                    </div>
                                </a>
                                <div className='language-flags-container'>
                                    <FormControl fullWidth>
                                        <Select
                                            id='language-select'
                                            value={lang}
                                            defaultValue={lang}
                                            onChange={(event) => setLanguage(event.target.value)}
                                        >
                                            {languageLists?.map((item, index) => (
                                                <MenuItem
                                                    className='language-flags-and-country-container'
                                                    key={index}
                                                    value={item.value}
                                                >
                                                    <img className='language-flags' src={item.imgSource}
                                                         alt={item.imgSource}/>
                                                    <span className='language-text'>{item.text}</span>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="promoAndroidMainText">{i18String('How to install the full Android app')}</div>
                            <div className="promoAndroidSecondText">{i18String('Since google play doesn\'t allow')}</div>
                        </div>
                    </div>
                </div>

                {/*PROMO PART*/}
                <div className="promoPart">
                    <div className="promoWrapper">
                        <div className="promoChapter">
                            <div className="promoChapter__number">1.</div>
                            <div className="promoChapter__text promoChapter__text--1">{i18String('Adjust the phone\'s setup')}</div>
                        </div>
                        <div className="promoSettings">
                            <div className="promoSettings__icons">
                                <div className="promoSettings__iconBlock promoSettings__iconBlock--1">
                                    <div className="promoSettings__rectangle promoSettings__rectangle--small">
                                        <div
                                            className="promoSettings__rectangleIcon promoSettings__rectangleIcon--settings"></div>
                                    </div>
                                    <div className="promoSettings__iconText promoSettings__iconText--1 promoSettings__iconText--fill1" dangerouslySetInnerHTML={{ __html: settingsText }}></div>
                                </div>
                                <div className="promoSettings__arrow"></div>
                                <div className="promoSettings__iconBlock promoSettings__iconBlock--1">
                                    <div className="promoSettings__rectangle promoSettings__rectangle--small">
                                        <div
                                            className="promoSettings__rectangleIcon promoSettings__rectangleIcon--security"></div>
                                    </div>
                                    <div
                                        className="promoSettings__iconText promoSettings__iconText--1 promoSettings__iconText--fill2" dangerouslySetInnerHTML={{ __html: securityText }}></div>
                                </div>
                                <div className="promoSettings__arrow"></div>
                                <div className="promoSettings__iconBlock promoSettings__iconBlock--1">
                                    <div className="promoSettings__rectangle promoSettings__rectangle--small">
                                        <div
                                            className="promoSettings__rectangleIcon promoSettings__rectangleIcon--switch"></div>
                                    </div>
                                    <div
                                        className="promoSettings__iconText promoSettings__iconText--1 promoSettings__iconText--fill3" dangerouslySetInnerHTML={{ __html: permitText }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*PROMO PART - 2*/}
                <div className="promoPart">
                    <div className="promoWrapper">
                        <div className="promoChapter">
                            <div className="promoChapter__number">2.</div>
                            <div className="promoChapter__text promoChapter__text--2">{i18String('Download the app')}</div>
                        </div>
                        <div className="promoPart--1">
                            <div className="promoSettings">
                                <div className="promoSettings__icons">
                                    <div
                                        className="promoSettings__iconBlock promoSettings__iconBlock--bigger promoSettings__iconBlock--1">
                                        <div className="promoSettings__rectangle promoSettings__rectangle--big">
                                            <div className="promoSettings__rectangleText">
                                                <a href="/footyassist.apk" download="footyassist.apk">
                                                    www.footyassist.com/footyassist.apk
                                                </a>
                                            </div>
                                        </div>
                                        <div
                                            className="promoSettings__iconText promoSettings__iconText--2 promoSettings__iconText--fill4">
                                            {i18String('Open link in the browser on your smartphone')}
                                        </div>
                                    </div>
                                    <div className="promoSettings__betweenText">{i18String('Or')}</div>
                                    <div
                                        className="promoSettings__iconBlock promoSettings__iconBlock--bigger promoSettings__iconBlock--1">
                                        <div className="promoSettings__rectangle promoSettings__rectangle--big">
                                            <div className="promoSettings__QR">
                                                <img style={{height: 200, width: 200}} src={androidQR} alt='Android QR code'/>
                                            </div>
                                        </div>
                                        <div
                                            className="promoSettings__iconText promoSettings__iconText--2 promoSettings__iconText--fill5">
                                            {i18String('Scan this QR code with your smartphone')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="promoInformation">
                                <div className="promoInformation__block">
                                    <div className="promoInformation__mobile"
                                         style={{backgroundImage: `url(${ lang === 'tr' ? androidMobileViewTR : androidMobileViewEN })`}}></div>
                                    <div className="promoInformation__text promoInformation__text--fill1" dangerouslySetInnerHTML={{ __html: ifUsualWarningText }}></div>
                                </div>
                            </div>
                        </div>
                        <div className="promoPart--2">
                            <div className="promoSettings__download">
                                <a href="/footyassist.apk" download="footyassist.apk">
                                    {lang === 'tr' ? 'İndir' : 'Download'}
                                </a>
                            </div>
                            <div className="promoSettings__downloadImage">
                                <div className="promoSettings__downloadMobile"
                                     style={{backgroundImage: `url(${ lang === 'tr' ? androidMobileViewTR : androidMobileViewEN })`}}></div>
                                <div className="promoSettings__downloadText" dangerouslySetInnerHTML={{ __html: youWillFindText }}></div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*PROMO PART - 3*/}
                <div className="promoPart">
                    <div className="promoWrapper">
                        <div className="promoChapter">
                            <div className="promoChapter__number">3.</div>
                            <div className="promoChapter__text promoChapter__text--3">{i18String('Install the app')}</div>
                        </div>
                        <div className="promoSettings promoSettings--2">
                            <div className="promoSettings__iconBlock promoSettings__iconBlock--2">
                                <div
                                    className="promoSettings__rectangle promoSettings__rectangle--small promoSettings__rectangle--small2">
                                    <div
                                        className="promoSettings__rectangleIcon promoSettings__rectangleIcon--download"></div>
                                </div>
                                <div className="promoSettings__text promoInformation__text--fill2" dangerouslySetInnerHTML={{ __html: youWillFindText }}></div>
                            </div>
                            <div className="promoSettings__phones">
                                <div className="promoSettings__mobile promoSettings__mobile--1"
                                     style={{backgroundImage: `url(${lang === 'tr' ? androidMobileView2TR : androidMobileView2EN})`}}></div>
                                <div className="promoSettings__mobile promoSettings__mobile--2"
                                     style={{backgroundImage: `url(${lang === 'tr' ? androidMobileView3TR : androidMobileView3EN})`}}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AndroidApkView;
